/*!
 * Start Bootstrap - SB Admin 2 Bootstrap Admin Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
 @font-face {
    font-family: 'Frankfurter Medium Std';
    src: url('../assets/fonts/FrankfurterMediumStd.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

body {
    font-family: Source Sans Pro, Arial, Times, serif;
}
#app{
    background-color:#EDF0F5;
}

::-webkit-input-placeholder { /* Edge */
    color: #C5C9D7 !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C5C9D7 !important;
  }
  
  ::placeholder {

    color: #C5C9D7 !important;
  }

input,select,textarea{
    outline:none;
}

input:focus{
    border: 1px solid #096D6F;
}


.container-login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color:#EDF0F5;
}

.frame-login{
    display: flex; 
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 728px;
    height: 100%;
    min-height: 100vh;
}

.container-form-login{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding:16px;
}

.background-login{
    position: relative;
    width: 728px;
    height: 100%;
    min-height: 100vh;
}

.logo-elogbook{
    position: absolute;
    margin-top: 220px;
    margin-left: 132px;
}



.elogbook-text {
    position: absolute;
    font-family: Frankfurter Medium Std;
    font-style: normal;
    font-weight: normal;
    font-size: 52.2745px;
    line-height: 47px;
    color: #FFFDFF;
    margin-top: 220.26px;
    margin-left: 225.31px;
}

.fkui-text{
    position:absolute;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 300;
    font-size: 25.0917px;
    letter-spacing: 0.522745px;
    color: #FFFDFF;
    margin-top: 264.66px;
    margin-left: 226.88px;
    margin-bottom: 362.34px;
}

.loginLogo{
    display: flex; 
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 336.46px;
    margin-left: 132px;
}

.loginLogo > div{
    display: flex; 
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.loginLogo img{
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
    margin-right: 8px;
}

.loginLogo h5{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #FFFDFF;
    margin-bottom: 16px;
    margin-right: 8px;
}

.container-error-login{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
}

.alert-login{
    position: relative;
    width: 100%;
    height: 62px;
    background-color: #F03738;
    opacity: 0.2;
    border-radius: 6px;
    padding: 8px 16px 8px 16px;
    z-index: 1
}

.alert-login-text{
    position: absolute;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    opacity: 1;
    color: #F03738;
    margin-top: 8px;
    margin-left: 16px;
}

.form-login{
    display: flex; 
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;  
    /* margin-left: 856px; */
}

.form-input{
    display: flex; 
    /* position: absolute; */
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
}

/* .form-forgot-password{
    display: flex; 
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 151px; 
    margin-left: 278px;
} */

.login-text{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 36px;
    color: #004445;
    margin-bottom: 16px;
}

.login-label{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #474748;
    margin-top: 16px;
    margin-bottom: 8px;
}

.login-textfield{
    width:296px;
    height:40px;
    padding-left: 16px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    margin-right: -40px;
    border: 1px solid #C5C9D7;
    border-radius: 6px;
    outline: none;
}

/* .login-textfield:focus{
    border: 1px solid #C5C9D7;
} */

.login-textfield-error{
    width:296px;
    height:40px;
    padding-left: 16px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    margin-right: -40px;
    border: 1px solid #F03738;
    border-radius: 6px;
    outline: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C5C9D7;;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C5C9D7;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #C5C9D7;
  }

.email-box{
    display: flex; 
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.email-error{
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #F03738;
    margin-bottom: -16px;
}

#email-error{
    width: 12px;
    height: 12px;
    display: none;
    margin-right: 8px;
}

.password-box{
    display: flex; 
    /* position: relative; */
    flex-direction: row;
    /* justify-content: flex-start; */
    align-items: center;
}

.show-password{
    background-image: url('../assets/login/show-icon.png');
    background-size: cover;
    width:24px;
    height:24px;
    cursor: pointer;
}

.hide-password{
    background-image: url('../assets/login/hide-icon.png');
    background-size: cover;
    width:24px;
    height:24px;
    cursor: pointer;
}

.forgot-password{
    display:flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 8px;
}

#forgot-password{
    text-decoration: none;
    color: #004445;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
}

.login-button{
    width: 296px;
    height: 40px;
    background: #004445;
    border-radius: 6px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    color: #FFFDFF;
    margin-top: 16px;
    margin-bottom: 16px;
}

.login-or img {
    width: 297px;
    height: 24px;
 } 

.login-confirm{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.form-forgot-password{
    display: flex; 
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 170px;  
    margin-left: 856px;
    margin-bottom: 16px;
}

.send-button{
    /* position: absolute; */
    width: 296px;
    height: 40px;
    background: #004445;
    border-radius: 6px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    color: #FFFDFF;
    margin-top: 32px;
    margin-bottom: 8px;
}

#link-login{
    text-decoration: none;
    color: #004445;
    font-size: 16px;
    line-height: 24px;
}

.login-sso-submit{
    display: flex;
    width: 296px;
    height: 40px;
    background:#F3BB1C;
    border-radius: 6px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    color: #FFFDFF;
    margin-top: 16px;
    margin-bottom: 16px;
    cursor: pointer;
}

.confirm-send-email{
    display: flex; 
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 258px;  
    margin-left: 856px;
    margin-bottom: 16px;
}

.confirm-email{
    margin-bottom:16px;
}

.form-new-password{
    display: flex; 
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 132px;  
    margin-left: 856px;
    margin-bottom: 16px;
}

.alert-password{
    position: absolute;
    width: 590px;
    height: 62px;
    margin-left: 744px;
    margin-top: 16px;
    margin-right: 16px;
    background: #F3BB1C;
    opacity: 0.2;
    border-radius: 6px;
    padding: 8px 16px 8px 16px;
}

.alert-password-saved{
    position: absolute;
    width: 590px;
    height: 62px;
    margin-left: 744px;
    margin-top: 16px;
    margin-right: 16px;
    background: #3CC13B;
    mix-blend-mode: normal;
    opacity: 0.2;
    border-radius: 6px; 
    padding: 8px 16px 8px 16px;
}

.alert-password-text{
    position: absolute;
    margin-left: 760px;
    margin-top: 24px;
    margin-right: 16px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    opacity: 100%;
    color: #F3BB1C; 
}

.alert-password-saved-text{
    position: absolute;
    margin-left: 760px;
    margin-top: 24px;
    margin-right: 16px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    opacity: 100%;
    color: #3CC13B;
    
}

.logo-elogbook-sidebar{
    height: 31px;
    width: 141px;
    margin-top: 17px;
    margin-left: 24px;
}

.elogbook-text-sidebar {
    position: absolute;
    font-family: Frankfurter Medium Std;
    font-style: normal;
    font-weight: normal;
    font-size: 21.7544px;
    line-height: 20px;
    color: #FFFDFF;
    margin-top: 12px;
    margin-left: 8px; 
}

.fkui-text-sidebar{
    position: absolute;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 300;
    font-size: 10.4421px;
    line-height: 12px;
    color: #FFFDFF;
    margin-top: 32px;
    margin-left: 8px;
}
.container-dashboard{
    display: flex;
    flex-direction: row;
    width:100%;
    height:100%;
    position: absolute;
    overflow-x: hidden;
}

.sidebar-dashboard{
    display: flex;
    flex-direction: column;
    width: 256px;
    height: 100%;
    background-color: #096D6F;
    position: absolute;
}

.content-dashboard{
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
    margin-left: 256px;
    position:relative;
    background: #EDF0F5;
}
.content-dashboard.sidebar-collapsed {
    margin-left: 88px;
}

.logo-container-sidebar-dashboard{
    width: 256px;
    height: 64px;
    background-color: #008C8F;
}

.navbar-dashboard{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    /* width: 100%; */
    height: 64px;
    background-color:#004445;
}



.navbar-title{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #FFFDFF;
}

.navbar-dropdown {
    position: absolute;
    width: 137px;
    height: 32px;
    /* margin-left: 763px; */
    color: #FFFDFF;
    position: absolute;
    margin-left: 50px;
  }

  .navbar-avatar-box{
      float:right;
      position:fixed;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 137px;
      height: 32px;
      right: 24px;
      color: #FFFDFF;
      z-index: 1;
  }

  .navbar-name{
    cursor:pointer;
  }

  .navbar-expand{
    cursor:pointer;
  }

  .navbar-avatar{
    width: 32px;
    height: 32px;
    background-color: #008C8F;
    border: 1px solid #004445;
    box-sizing: border-box;
    border-radius: 100px;
    margin-right: 8px;
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 2px;  
    }

    .dropdown-menu-box{
        /* display:block; */
        position: absolute;
        width: 256px;
        height: 157px;
        top: 48px;
        right: 64px;
        background-color: #FFFDFF;
        border-radius: 4px;
        filter: drop-shadow(2px 4px 15px rgba(114, 110, 115, 0.2));
        z-index: 1;
    }

    .dropdown-menu-box-bottom{
        display: flex;
        flex-direction: column;
    }

    .dropdown-item-avatar-box{
      display: flex;
      flex-direction: row;
    }

    .dropdown-item-menu{
      display: flex;
      flex-direction: row;
      margin-left: 16px;
      margin-top: 12px;
        margin-right: 8px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #474748;
        text-decoration: none;
        cursor:pointer;
    }

    .dropdown-item-menu:hover{
        color: #474748;
    }

    .dropdown-item-menu  img{
        width: 24px;
        height: 24px;
    }
    
  .dropdown-item-avatar{
    display:flex;
    width: 40px;
    height: 40px;
    background-color: #008C8F;
    border: 1px solid #004445;
    box-sizing: border-box;
    border-radius: 100px;
    margin-left: 16px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .dropdown-item-text{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: -8px;
  }

.dropdown-item-username{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #474748;
    /* margin-left: 16px;
    margin-top: 16px; */
}

.dropdown-item-id{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* margin-left: 16px; */
    color: #474748;

}



.side-menu{
    display: flex; 
    flex-direction: column;
    padding-top: 24px;
}

.side-menu-tab{
    display: flex; 
    flex-direction: row;
    width: 240px;
    height: 40px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 6px;
    /* //background-color: transparent; */
    text-decoration: none;
}
.side-menu-tab.side-menu-tab-collapsed {
    width: 72px;
}

.menu-active {
    background-color: #008C8F;
  }

.side-menu-tab > img {
    width: 24px;
    height: 24px;
    margin-top:8px;
    margin-right: 16px;
    margin-bottom: 8px;
    margin-left: 24px;
}

.side-menu-tab > div {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #C5C9D7;
    margin-top:8px;
    margin-bottom: 8px;
}
    .popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: rgba(0,0,0, 0.5);
    }
    .popup-inner {
        position: absolute;
        width: 284px;
        height: 224px;
        /* left: 498px;
        top: 172px; */
        margin: auto;
        background: white;
    }

    .popup-inner img {
        margin-top:24px;
        margin-left:110px;
        margin-bottom:8px;
        width: 64px;
        height: 48px;
    }

    .logout-title{
    height: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #004445;
    }

    .logout-text{
        height: 40px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #474748;
        margin-top:8px;
        margin-left:24px;
        margin-bottom: 16px;
    }

    .logout-button-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 16px 24px;
    }

    .logout-cancel-button{
        width: 112px;
        height: 32px;
        border: 1px solid #004445;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #004445;
        margin-right:16px;
    }

    .logout-button{
        width: 112px;
        height: 32px;
        background: #004445;
        border-radius: 6px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #FFFDFF;
    }

/* dashboard */
    .dashboard-box{
        position: relative;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 24px;
        width: 100%;
        height: 100%;
/*         

        position:relative;
        overflow-y: auto;
        padding-bottom: 24px;
        width: 100%;
        height:100%;
        padding: 24px;
        display:flex;
        flex-direction: column; */
    }

    .top-container-dashboard{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 152px;
        background-color: #004445;
        box-shadow: 4px 8px 24px rgba(114, 110, 115, 0.2), 4px 8px 24px rgba(114, 110, 115, 0.2);
        border-radius: 6px;
    }

    .quote-dashboard{
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        width:500px;
        margin-top: 24px;
        margin-left: 24px;

    }

    .quote-username-dashboard{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.18px;
        color: #FFFDFF;
        margin-bottom: 8px;
    }

    .quote-content-dashboard{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #FFFDFF;
    }


    .quote-logo-dashboard{
        width: 381px;
        height: 152px;
        margin-left: 150px;
    }

    .stase-container{
        display: flex;
        flex-direction: column;
        
    }

    .label-stase{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }

    .stase{
        width: 256px;
        cursor: pointer;
        /* border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-left:24px;
        color:  #C5C9D7;
        padding-left: 16px; */
    }

    .row1-container-dashboard{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        /* margin-left: 24px; */
    }

    .row1-container-dashboard-content{
        height: 140px;
        width: 100%;
        background: #FFFDFF;
        border-radius: 6px;
        margin-top: 16px;
        margin-right: 16px;
        box-shadow: 2px 4px 15px rgba(114, 110, 115, 0.2);
    }

    /* #row1-container-dashboard-case{
        width: 296px;
    }

    #row1-container-dashboard-competency{
        width: 334px;
    }

    #row1-container-dashboard-stase{
        width: 400px;
    } */

    .row1-container-dashboard-content img{
        float:right;
        width: 72px;
        height: 72px;
    }

    .row1-container-dashboard-mobile{
        display:none;
    }

    .row1-text{
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top:80px;
        margin-left: 24px;
    }

    .progress-number{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 36px;
        color: #004445;
    }

    .total-number{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #A5A5AA;
    }

    .row1-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #A5A5AA;
        margin-left: 8px;
    }

    .row2-container-dashboard{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        /* margin-left: 24px; */
    }

    .row2-container-dashboard-content{
        /* position: relative; */
        display: flex;
        flex-direction: column;
        width: 523px;
        height: 274px;
        background: #FFFDFF;
        border-radius: 6px;
        margin-top: 16px;
        margin-right: 16px;
        padding-top: 28px;
        padding-left: 24px;
        padding-right: 24px;
        box-shadow: 2px 4px 15px rgba(114, 110, 115, 0.2);
    }

    .row2-title{
        display: flex;
        flex-direction: row;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #474748;
    }

    .row2-title > div{
        margin-right: 8px;
    }

    .row2-title img{
        cursor: pointer;
    }

    .tooltiptext{
        visibility: visible;
        /* display:block; */
        position:absolute;
        width:223px;
        height:56px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #474748;
        border-radius:6px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #FFFDFF;
        padding: 16px 16px 16px 16px;
    }

    .row2-select{
        position:relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .row2-select-period{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
    }

    .row2-select-period img{
        cursor: pointer;
    }

    .dashboard-graph{
        margin-top:24px;
        width:475px;
        height:134px;
    }

    .period{
        width: 104px;
        height: 32px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        padding-left: 8px;
        margin-top: 8px;
    }

    #dashboard-table-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #474748;
        margin-top:16px;
        margin-bottom:16px;
    }

    #dashboard-table-content{
        width: 701px;
        border-collapse: collapse;
        background-color: #FFFDFF; 
    }

    #dashboard-table-content th{
        height: 56px;
        border: 1px solid #FFFFFF;
        background-color: #004445;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFDFF;
        padding-left: 8px;
    }

    #dashboard-table-content th > img{
        cursor: pointer;
    }

    #dashboard-table-content th:first-child{
        border-radius: 6px 0px 0px 0px;
        width:427px;
    }

    #dashboard-table-content th:nth-child(2){
        width:108px;
    }

    #dashboard-table-content th:last-child{
        border-radius: 0px 6px 0px 0px;
        width:166px;
    }

    #dashboard-table-content td{
        height:52px;
        border: 1px solid #EDF0F5;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        padding-left: 8px;
    }

    #dashboard-table-content tr:nth-child(even){
        border: 1px solid #EDF0F5;
        background-color: #DBF1FF;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        padding-left: 8px;
    }



/* logbook */

    .logbook-nodata{
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logbook-nodata img{
        width: 215px;
        height: 161 px;
    }

    .logbook-data{
        position:absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    .logbook-filter-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #474748;
    }

    .logbook-filter{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: nowrap;
        height: 100px;
    }

    /* 
     * Wrap .logbook-filter inside two parent divs to overcome different overflow-x and -y values
     * See:
     * - https://medium.com/@justicart/overflow-x-scroll-overflow-y-visible-c1a98238e002
     * - https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue 
     */
    .logbook-filter-wrapper{
       height: 100px;
       position: relative;
    }

    .logbook-filter-container {
       overflow-x: scroll;
       position: absolute;
       top: 0;
       height: 650px;
       width: 100%;
    }

    #container-filter {
        width: 761px;
        height: 72px;
        border: 1px solid #ccc;
        overflow-x:auto;
      }
  
      #content-filter {
        display: flex;
        flex-direction: row;
      }

      .filter-box{
        display: flex;
        flex-direction: column;
        margin-right:16px;

      }

      .filter-box-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #474748;
        margin-bottom: 16px;
        margin-top: 16px;
      }

      .filter-box-dropdown{
        width:150px;
        height:40px;
        background-color: #FFFDFF;
        border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
      }

      .filter-box-dropdown:focus{
        border: 1px solid #096D6F
      i}

      .rmsc{
          --rmsc-gray: #C5C9D7 !important;
          --rmsc-main: #096D6F !important;
          --rmsc-border: none !important;
          --rmsc-radius: 6px !important;
      }

      .filter-date-dropdown{
        /* position: relative; */
        width:150px;
        height:40px;
        background-color: #FFFDFF;
        border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        padding:8px;
      }

      

      .filter-box-dropdown > input{
          padding-left: 8px;
      }

    .filter-box-dropdown select{
        color: #C5C9D7;
    }

    .filter-box-dropdown option{ 
        color: #000000;
    }

    .filter-box-dropdown option[disabled]:first-child { display: none; }

    .category{
        width:160px;
        /* height: 32px; */
        /* border: 1px solid #C5C9D7; */
        box-sizing: border-box;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        margin-left: 24px;
        /* padding-left: 16px; */
        /* z-index:200; */
    }
    

    .logbook-search-box{
        width:260px;
        display: flex;
        flex-direction: row;
    }

    .logbook-search-box img{
        margin-top: 4px;
    }

    
.TableFixedCell-fixedCell-10{
        background-color: none;
    }

    #search{
        width:260px;
        height: 32px;
        background: #FFFDFF; 
        border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding-left: 16px;
        margin-right: -64px;
    }

    .logbook-filter-result{
        margin-top:16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap:wrap; 
    }

    .logbook-filter-item{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: #008C8F;
        color:#FFFDFF;
        border-radius: 16px;
        padding:6px 16px 6px 16px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
    }

    .logbook-filter-item img{
        margin-left:8px;
    } 

    .logbook-filter-cancel{
        width:20px;
        height:20px;
        cursor:pointer;
    }

    .logbook-filter-cancelAll{
        display: flex;
        width:160px;
        height:40px;
        background-color: #004445;
        color:#FFFDFF;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor:pointer;
        margin-top: 16px;
    }

    .logbook-filter-cancelAll-disabled{
        display: flex;
        width:160px;
        height:40px;
        background: #DCE0E9;
        color: #A5A5AA;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor:default;
        margin-top: 16px;
    }

    .logbook-result-table{
        z-index: 0;
    }

    .logbook-table-content{
        background-color:#DCE0E9;
    }

    .logbook-table-content th{
        /* height: 56px; */
        width:100%;
        border: 1px solid #FFFFFF;
        background-color: #004445;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFDFF;
        padding-left: 8px;
        text-align:center;
    }

    .logbook-table-content th div{
        display:flex;
        justify-content:center;
    }

    .logbook-table-content th:nth-child(1){
        border: 0px solid #000000;
        text-align:center;
        border-top: 1px solid #FFFFFF;
        border-left: 1px solid #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
    }

    .logbook-table-content th:nth-child(2){
        border: 0px solid #000000;
        text-align:center;
        border-top: 1px solid #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
    }

    .logbook-table-content tr{
        
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        padding-left: 8px;
        
    }

    .logbook-table-content td{
        border: 1px solid #DCE0E9;
        text-align:center;
    }

    .logbook-table-content tr:nth-child(even) > td:nth-child(1) {
        /* border: 0px solid #000000; */
        border-bottom: 1px solid #DCE0E9;
        text-align:center;
        background-color: #DBF1FF;
        color:#000000;
    }

    .logbook-table-content tr:nth-child(even) > td:nth-child(2) {
        border: 0px solid #000000;
        border-bottom: 1px solid #DCE0E9;
        border-left: 1px solid #DCE0E9;
        border-right: 1px solid #DCE0E9;
        text-align:center;
        background-color: #DBF1FF ;
    }

    .logbook-table-content tr:nth-child(even) > td:nth-child(17) {
        border: 0px solid #000000;
        border-bottom: 1px solid #DCE0E9;
        border-left: 1px solid #DCE0E9;
        border-right: 1px solid #DCE0E9;
        text-align:center;
        background-color: #DBF1FF ;
    }

    .logbook-table-content tr:nth-child(odd){
        background-color:  #FFFFFF ;
    }

    .logbook-table-content tr:nth-child(even){
        background-color:  #DBF1FF ;
    }

    .table tr{
        background-color:  #DBF1FF;
    }

    .table-sort-label{
        cursor: pointer;
    }

    .delete-row-icon{
        cursor:pointer;
        margin-left: 16px;
    }

    .row-detail{
        display: flex;
        flex-direction:row;
        /* flex-wrap:wrap; */
        /* background-color: #C43006; */
        /* position: absolute;
        float:left; */
        /* z-index: 0; */
        /* overflow: hidden; */
    }

    .row-detail-column{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        margin-left: 44px;
        margin-right:44px;
        color: #726E73;
    }

    .row-detail-label{
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color:#474748;
        margin-top:8px;
    }

    .sort-table-icon{
       margin-left: 8px;
    }

    .logbook-popup{
        position:fixed;
        /* width:; */
        height: 300px;
        background-color: white;
        color:black;
        z-index: 450;
    }

    #logbook-new-entry{
        float:right;
        position:fixed;
        right: 24px;
        width: 229px;
        height: 32px; 
        background-color: #008C8F;
        border-radius: 6px;
        cursor: pointer;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFDFF;
        
        /* margin-left: 433px; */
    }
    
    .btn-hijau {
        background-color: #008C8F;
        border-radius: 6px;
        cursor: pointer;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        color: #FFFDFF;
        padding: 7px 12px;
    }

    #logbook-save-entry{
        float:right;
        position:fixed;
        right: 24px;
        width: 229px;
        height: 32px; 
        background: #008C8F;
        border-radius: 6px;
        cursor: pointer;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFDFF;
        /* margin-left: 737px; */
    }

    #logbook-back{
       text-decoration : none;
       color: #FFFDFF;
    }

    .logbook-box{
        position:relative;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height:100%;
        padding: 24px;
        display:flex;
        flex-direction: column;
    }

    .logbook-entry{
        /* display: none; */
        display:flex;
        flex-direction: column;
        max-width: 560px;
        /* height: 1364px; */
        margin-left: 24px;
        margin-top: 24px;
        padding-top:24px;
        padding-left:24px;
        padding-right: 24px;
        padding-bottom: 24px;
        background: #FFFDFF;
        border-radius: 6px;
    }

    .logbook-entry-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #474748;
        margin-bottom:24px;
    }

    .logbook-entry-label{
        position: re;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

    }

    .logbook-entry-input{
        max-width:326px;
        height:40px;
        border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        margin-bottom: 16px;
        padding-left: 8px;
        outline: none;
    }

    .logbook-entry-input:focus{
        border: 1px solid #096D6F;
    }

    .logbook-entry-select{
        
        max-width:326px;
        height:40px;
        box-sizing: border-box;
        border-radius: 6px;
        margin-bottom: 16px;
        outline:none !important;
    }

    .logbook-entry-select:focus{
        border: 1px solid #096D6F;
    }

    .logbook-entry-input option{ 
        color: #000000;
    }

    .logbook-entry-input option[disabled]:first-child { display: none; }

    .logbook-entry textarea{
        max-width: 326px;
        height: 80px;
        padding-left: 8px;
        border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-radius: 6px;
    }

    .logbook-entry textarea:focus{
        border: 1px solid #096D6F;
    }

    #idDate{
        padding-right: 8px;
    }

    .logbook-entry-age{
        max-width:326px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
    }

    #idAge{
        width:160px;
        height:40px;
    }

    #idTime{
        width:150px;
        height:40px;
    }

/* skdi */

    .skdi-bar-title{
        margin-left: 16px;
        margin-right:16px;
    }

    .skdi-bar-competency{
        width: 32px;
        height: 32px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #474748;
        margin-left: 8px;
        margin-right: 8px;
        background: #FFFDFF;
        border: 1px solid #DCE0E9;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
    }

    .skdi-search-box{
        float: right;
        right: 24px;
        position: fixed;
        width:260px;
        display: flex;
        flex-direction: row;
    }

    .skdi-search-box img{
        margin-top: 4px;
    }

    #skdi-search{
        width:326px;
        height: 32px;
        background: #FFFDFF; 
        border: 1px solid #C5C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        padding-left: 16px;
        margin-right: -64px;
    }

    .skdi-box{
        position:relative;
        overflow-y: auto;
        padding-bottom: 24px;
        width: 100%;
        height:100%;
        padding: 24px;
        display:flex;
        flex-direction: column;
    }

    .skdi-row1{
        display: flex;
        flex-direction: row;
        width: 300px;
        margin-bottom: 16px;
        z-index: 0;
    }

    .skdi-row1-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #474748;
    }

    .skdi-row1-info{
        float: right;
        right: 24px;
        position: absolute;
        display: flex;
        flex-direction: row;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #004445;
        cursor:pointer;
    }

    .skdi-row1-info:hover{
        color: #004445;
    }

    .skdi-row1-info img{
        margin-right: 8px;
        cursor: pointer;
    } 


    .skdi-select{
        width: 256px;
        cursor: pointer;
    }

    .multiselect {
        width: 200px;
        background-color: #FFFDFF;
      }
      
      .selectBox {
        position: relative;
      }
      
      .selectBox select {
        width: 100%;
      }
      
      .overSelect {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      
      #checkboxes {
        display: none;
        margin-left: 8px;
        margin-right: 8px;
      }
      
      #checkboxes label {
        display: block;
      }
      
      #checkboxes label:hover {
        background-color: #1e90ff;
      }

      .skdi-table-title{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #474748;
        margin-top:16px;
        margin-bottom:16px;
    }

    .skdi-table-content{
        width: 730px;
        border-collapse: collapse;
        background-color: #FFFDFF; 
    }

    .skdi-table-content th{
        height: 56px;
        border: 1px solid #FFFFFF;
        background-color: #004445;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFDFF;
        padding-left: 8px;
        
    }

    .skdi-table-content th:first-child{
        border-radius: 6px 0px 0px 0px;
        width:427px;
    }

    .skdi-table-content th:nth-child(2){
        width:108px;
    }

    .skdi-table-content th:last-child{
        border-radius: 0px 6px 0px 0px;
        width:166px;
    }

    .skdi-table-content td{
        height:52px;
        border: 1px solid #EDF0F5;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        padding-left: 8px;
    }

    .skdi-table-content th > img{
        cursor: pointer;
    }

    .skdi-table-content tr:nth-child(even){
        border: 1px solid #EDF0F5;
        background-color: #DBF1FF;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        padding-left: 8px;
    }

    .skdi-sort{
        display: flex;

    }

    .info-box-skdi{
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        padding:24px;
    }

    .info-skdi-title{
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #474748;
        margin-bottom: 16px;
    }

    .info-skdi-subtitle{
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #474748;
        margin-bottom: 4px;
    }

    .info-skdi-paragraph{
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #474748;
        margin-bottom: 16px;
    }

/* profile */
    .navbar-divider{
        width: 1110px;
        height: 1px;
        background-color:#EDF0F5;
        opacity: 0.3;
    }

    .profile-bar{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 48px;
        background-color:#004445;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #FFFDFF;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .profile-bar img{
        width: 24px;
        height: 24px;
        margin-left: 24px;
        margin-right: 16px;
        cursor: pointer;
    }

    .profile-box{
        position:relative;
        display: flex;
        flex-direction: column;
        width: 374px;
        background: #FFFDFF;
        border-radius: 6px;
        padding: 24px;
    }
    
    .profile-avatar-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-avatar{
        display: flex;
        flex-direction:column;
        width: 56px;
        height: 56px;
        background: #008C8F;
        border: 1px solid #004445;
        box-sizing: border-box;
        border-radius: 100px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        align-items: center;
        letter-spacing: 0.18px;
        color: #FFFFFF;
        padding-top: 16px;
    }

    .profile-avatar img {
        float: right;
        width: 16px;
        height: 16px;
        margin-left: 36px;
        cursor:pointer;
    }

    .profile-name{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.18px;
        color: #474748;
        margin-top: 16px;
        margin-bottom:24px;
    }

    .profil-id-box{
        display: flex;
        flex-direction:column;
    } 

    .profile-id-label{
        margin-left: 24px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #C5C9D7;
    }

    .profile-id-label img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        cursor:pointer;
    }

    .profile-id-value{
        margin-left: 24px;
        margin-bottom: 16px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #474748;
    }

    #profile-update-photo{
        display:none;
    }

    /* #profile-update-password{
        display:none;
    } */

    .profile-update-password-text{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #474748;
        margin-top:24px;
        /* margin-left:24px; */
        margin-bottom: 24px;
    }

    .profil-update-box{
        display: flex;
        flex-direction:column;
        /* position: relative; */
    } 

    .profile-update-label{
        margin-top: 16px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #474748;
    }

    .profile-password-box{
        display: flex; 
        flex-direction: row;
        
    }
    

    .profile-textfield{
        width:326px;
        height:40px;
        background: #FFFDFF;
        padding-left: 16px; 
        padding-bottom: 8px;
        padding-right: 8px;
        margin-top: 8px;
        margin-right: -40px;
        border: 1px solid #C5C9D7;
        border-radius: 6px;
    }

    .profile-textfield-error{
        width:326px;
        height:40px;
        background: #FFFDFF;
        padding-left: 16px; 
        padding-bottom: 8px;
        padding-right: 8px;
        margin-top: 8px;
        margin-right: -40px;
        border: 1px solid #F03738;
        border-radius: 6px;
    }

    .profile-textfield:focus{
        border: 1px solid #096D6F;
    }

    #toggle-profile{
        margin-top: 16px;
    }

    .profile-update-password-button-box{
        
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top:72px;
        /* margin-left: 110px; */
    }

    .profile-update-password-save-button{
        width: 112px;
        height: 40px;
        background: #004445;
        color: #FFFDFF;
        text-align: center;
        border-radius: 6px;
        line-height: 40px;
        cursor: pointer;
    }

    .profile-update-password-cancel-button{
        width: 112px;
        height: 40px;
        margin-left: 16px;
        border: 1px solid #004445;
        text-align: center;
        box-sizing: border-box;
        border-radius: 6px;
        color: #004445;
        line-height: 40px;
        cursor: pointer;
    }

    .error-password{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #C43006;
        /* margin-left: 24px; */
    }

    .error-password img{
        width: 12px;
        height: 12px;
    }

    .profile-popup{
        display:none;
    }

    .popup-inner-profile {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 280px;
        height: 176px;
        margin: auto;
        background: white;
    }

    .popup-inner-profile img{
        width:48px;
        height:48px;
    }

    .popup-profile-head{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #004445;
        margin-top: 8px;
    }

    .popup-profile-content{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #474748;
        margin-top:8px;
    }

    .popup-content {
        background: none !important;
        border: none !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     }

    .popup-new-entry{
        max-width: 50%;
        /* height: 264px; */
        background-color: #FFFDFF;
        box-shadow: 2px 4px 15px rgba(114, 110, 115, 0.2);
        border-radius: 6px;
        padding:24px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .popup-new-entry-title{
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #004445;
        margin:8px;
    }
    .popup-new-entry-content{
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #474748;
        margin-bottom:16px;
    }
    .popup-new-entry-button{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .popup-new-entry-button-cancel{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 112px;
        height: 32px;
        border: 1px solid #004445;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #004445;
        margin-right: 16px;
        margin-bottom: 16px;
        cursor: pointer;
    }

    .popup-new-entry-button-save{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 112px;
        height: 32px;
        background-color: #004445;
        border-radius: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #FFFDFF;
        cursor: pointer;
    }

/* for mobile web screen */

@media only screen and (max-width:650px) {


    .container-login {
        min-width: 0px;
        width: 100%;
        height: 80%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        background:#EDF0F5;
        overflow: hidden;
      }

    .frame-login{
        display: flex; 
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 650px;
        width: 100%;
        height: 80%;
    }

    .background-login {
        width: 100%;
        height: 100%;
    }

    .logo-elogbook{
       width: 54px;
       height: 54px;
       margin-top: 24px;
       margin-left:60px;
    }
    .elogbook-text {
        font-size: 38px;
        line-height: 34px;
        font-style: normal;
        font-weight: normal;
        margin-top: 24px;
        margin-left: 125px;
    }
    
    .fkui-text{
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        margin-top: 56px;
        margin-left: 125px;;
    }

    .loginLogo{
        display: none;
    }

    .login-box{
        display:flex;
        flex-direction: column;
    }

    .container-form-login{
        display: flex;
        position: absolute;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: center;
        padding:16px;
    }

    .form-login{
        width: 100%;
        height: 100%;
        display: flex; 
        position: absolute;
        flex-direction: column;
        align-items: center;
        margin-top: 124px;  
        margin-left: 0px;
        background:#EDF0F5;
    }


    .alert-login{
        position: absolute;
        width: 92%;
        height: 92px;
        margin-left:16px;
        margin-top: 124px;
        margin-right: auto;
        z-index: 300;
        background: #F03738;
        opacity: 0.2;
        border-radius: 6px;
        padding: 8px 16px 8px 16px;
    }

    .alert-login-text{
        position: absolute;
        margin-left: 20px;
        margin-top: 124px;
        margin-right: 16px;
        z-index: 300;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        opacity: 1;
        color: #F03738;
    }
    

    .form-input{
    display: flex; 
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    }

    .form-forgot-password{
        width: 100%;
        height: 80%;
        display: flex; 
        position: absolute;
        flex-direction: column;
        align-items: center;
        margin-top: 124px;
        margin-left: 0px;
        background:#EDF0F5;
    }
    .login-text{
        margin-top: 24px;
        text-align :left;
    }
    .confirm-send-email{
        width: 100%;
        height: 80%;
        margin-top: 124px;  
        margin-left: 0px;
        background:#EDF0F5;
    }
    .confirm-send-email > div{
        margin-left: 24px;
    }
    .form-new-password{
        width: 100%;
        height: 80%;
        align-items: center;
        margin-top: 100px;  
        margin-left: 0px;
        padding-top: 116px;
        background:#EDF0F5;
    }
    .alert-password{
        position: absolute;
        width: 92%;
        height: 92px;
        margin-left:16px;
        margin-top: 124px;
        margin-right: auto;
        z-index: 300;
        background: #F3BB1C;
        opacity: 0.2;
        border-radius: 6px;
        padding: 8px 16px 8px 16px;
    }
    .alert-password-saved{
        position: absolute;
        width: 92%;
        height: 40px;
        margin-left: 16px;
        margin-top: 124px;
        margin-right: 16px;
        z-index: 300;
        background: #3CC13B;
        mix-blend-mode: normal;
        opacity: 0.2;
        border-radius: 6px; 
        padding: 8px 16px 8px 16px;
    }
    
    .alert-password-text{
        position: absolute;
        margin-left: 20px;
        margin-top: 124px;
        margin-right: 16px;
        z-index: 300;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        opacity: 100%;
        color: #F3BB1C; 
    }
    
    .alert-password-saved-text{
        position: absolute;
        margin-left: 20px;
        margin-top: 124px;
        margin-right: 16px;
        z-index: 300;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        opacity: 100%;
        color: #3CC13B;
        
    }

    /* Note: collapsed state is actually shown on mobile */
    .sidebar-dashboard.sidebar-collapsed{
        display: block;
    }
    .sidebar-dashboard {
        display: none;
    }

    .content-dashboard {
        margin-left: 0px;
        overflow: hidden;
    }
    .content-dashboard.sidebar-collapsed {
        margin-left: 88px;
    }

    .dashboard-box{
        /* position:relative; */
        width: 100%;
    }

    .top-container-dashboard{
        
        width: 100%;
        height: 100%;
        padding: 24px;
    }
    .quote-dashboard{
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        width:500px;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
    }

    .quote-username-dashboard{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.18px;
        color: #FFFDFF;
        margin-bottom: 8px;
    }

    .quote-content-dashboard{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #FFFDFF;
    }
    .quote-logo-dashboard{
        display: none;
    }

    .stase{
        width: 100%;
    }

    .row1-container-dashboard{
        width:fit-content;
        display:none;
    }

    .row1-container-dashboard-mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:fit-content;
        display:block;
    }

    .row1-container-dashboard-content {
        width: 282px;
        height: 92px;
    }

    .row1-text {
        margin-top: 16px;
        margin-left: 16px;
    }
    .row2-container-dashboard {
        flex-direction: column;
    }

    .row2-container-dashboard-content {
        width: 100%;
        height: 228px;
    }

    .dashboard-graph{
        width: 100%;
    }

    .dashboard-graph img{
        width: 100%;
    }

    #dashboard-table-content{
        width: 100%;
    }


    /*navbar*/
    .navbar-avatar-box{
        flex-direction: row-reverse;
        right: 16px;
        color: #FFFDFF;
        z-index: 1;
    }

    .navbar-expand{
        display: none;
    }
    .navbar-name{
        display: none;
    }

    
    /*profile*/
    .profile-box{
        width:100%;
    }
    .profile-avatar-box{
        align-items: flex-start;
        margin-left: 24px;
    }

    .skdi-search-box{
        display:none;
    }
    .skdi-box{
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }

    .skdi-row1-info{
        display:none;
    }

    .skdi-select{
        width: auto;
    }

    .skdi-table-content{
        width:100%;
    }

  }

